/* You can add global styles to this file, and also import other style files */

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    scroll-behavior: smooth;
}

a {
    color: #fff;
}

a:hover {
    color: #fff;
    
}
.alert{
    position: fixed;
    z-index: 100;
    top: 20px;
    right: 15px;
  }
  .success-alert{
    background-color:#d1e7dd ;
    color: #146c43;
  }
  .fail-alert{
    background-color: #f8d7da;
    color: #b02a37;
  }
  